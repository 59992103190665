.detail-container {
  margin-top: calc(96px + 32px);
  margin-bottom: 64px;
}

.details {
  width: 1060px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px auto;
}

.detail-image-container {
  margin: 0px auto;
}

.image-section {
  width: 50%;
}

.info-section {
  width: 400px;
}

.info-title {
  font-size: 20px;
}

.info-body {
  margin-top: 32px;
  font-family: 'Inter-Regular';
  font-size: 18px;
}

.info-body .description {
  font-size: 16px;
  line-height: 1.2rem;
}

.info-body .separator {
  margin-top: 32px;
  width: 64px;
  border-top: 1px solid black;
}

.info-body .price {
  margin-top: 32px;
  font-size: 18px;
}

.variants {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  width: auto;
}

.button-container {
  margin-top: 32px;
}

.button-container .btn {
  font-family: 'Roboto Mono', sans-serif;
  color: white;
  background-color: #0d8ae1;
  font-size: 14px;
  border: none;
  padding: 10px 12px;
  cursor: pointer;
}

.button-container .btn:hover {
  background-color: #0e83d6;
}

.button-container .btn:active {
  background-color: #0c78c5;
}

.btn-sold-out {
  font-family: 'Roboto Mono', sans-serif;
  color: white;
  background-color: #bababa;
  font-size: 14px;
  border: none;
  padding: 10px 12px;
  cursor: not-allowed;
}

.detail-icon {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  cursor: pointer;
  opacity: 0.5;
  height: 24px;
}

.detail-icon-back {
  left: 8px; 
}

.detail-icon-next {
  right: 8px;
}

.detail-icon:hover {
  opacity: 0.75;
}

@media (max-width: 1140px) {
  .details {
    display: block;
    width: 100vw;
  }

  .detail-image-container {
    width: 100%;
  }

  .image-section {
    margin: 0px auto;
    width: 80%;
  }

  .info-section {
    width: 80%;
    margin: 32px auto;
  }
}