.app-container {
  min-height: calc(100vh - 264px);
}

.first-time-container {
  display: flex;
  align-items: center;
  background: #fff;
  height: 100vh;
  width: 100vw;
  cursor: pointer;
  transition: opacity .2s cubic-bezier(.25,.46,.45,.94);
}

.first-time-container img {
  width: 600px;
  margin: 0px auto;
}

@media (max-width: 1140px) {
  .first-time-container img {
    width: 80%;
    margin: 0px auto;
  }
}