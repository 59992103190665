.items-container {
  margin-top: 144px;
}

.items {
  width: 1140px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px auto 64px;
}

@media (max-width: 1140px) {
  .items-container {
    margin-top: calc(96px + 32px);
  }
  
  .items {
    display: block;
    width: 100%;
  }
}