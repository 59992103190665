.info-container {
  margin-top: 144px;
  margin-bottom: 64px;
}

.info {
  width: 960px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px auto;
}

.info .info-col {
  width: 33%;
}

.info-logo {
  width: 196px;
}

.info-big {
  padding-top: 16px;
  font-size: 32px;
}

.info-social {
  margin-top: 32px;
}

.info-social-header {
  margin-top: 16px;
  font-size: 18px;
  font-weight: 500;
}

.info-social-text {
  font-family: 'Inter-Regular';
  font-size: 15px;
}

.info-social-text a {
  text-decoration: none;
  color: #000;
}

.info-social-text a:hover {
  color: #0d8ae1;
}

.info-content {
  padding-right: 64px;
  line-height: 1.4;
}

.returns {
  margin-top: 32px;
}

.policies {
  padding-right: 38px;
}

.info-header {
  font-size: 20px;
  font-weight: 500;
}

.info-text {
  font-family: 'Inter-Regular';
  font-size: 16px;
  margin-top: 24px;
}

@media (max-width: 1140px) {
  .info {
    width: 100%;
    flex-direction: column;
    text-align: center;
  }

  .info-big {
    font-size: 24px;
  }
  
  .info-logo {
    margin: 0px auto;
  }

  .info .info-col {
    width: 90%;
    margin: 32px auto 0px;
  }

  .info-content {
    padding-right: 0px;
  }

  .info-content:first-child {
    display: flex;
    flex-direction: column;
  }

  .info-content:first-child .info-logo {
    align-self: center;
  }

  .policies {
    padding-bottom: 32px;
  }
}

