.variant-container {
  width: 32px;
  height: 32px;
  text-align:center;
  border-radius: 50%;
  border: 1px solid #666;
  cursor: pointer;
  margin-right: 16px;
}

.variant-text {
  position: relative;
  top: 5px;
}

.variant-container-selected {
  width: 32px;
  height: 32px;
  text-align:center;
  border-radius: 50%;
  border: 1px solid #000;
  cursor: pointer;
  margin-right: 16px;
  background-color: #000;
  color: #fff;
}