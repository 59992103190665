.cart-container {
  margin-top: 144px;
  margin-bottom: 64px;
}

.cart {
  width: 840px;
  margin: 0px auto;
}

.cart-title {
  width: 100%;
  font-size: 24px;
  text-align: center;
}

.cart-table {
  width: 840px;
  margin-top: 32px;
  border-collapse: collapse;
}

thead {
  border-bottom: 1px solid #000;
  border-collapse: separate;
}

thead tr td {
  padding: 24px 0px;
}

.row-image {
  width: 168px;
}

.row-title {
  width: 33%;
  padding-left: 24px !important;
}

tbody {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.cart-row td {
  vertical-align: top;
  font-size: 14px;
  padding: 24px 0px;
  font-weight: 500;
}

.cart-image-container {
  background-size: cover;
  background-position: center;
  height: 168px;
  width: 168px;
}

.cart-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.footer-contents {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}

.subtotal-title {
  margin-top: 24px;
}

.subtotal-value {
  margin-top: 8px;
  font-size: 24px;
  font-weight: 500;
}

.checkout-button-container {
  margin-top: 32px;
}

.checkout-button {
  font-family: 'Roboto Mono', monospace;
  color: white;
  background-color: #0d8ae1;
  font-size: 14px;
  border: none;
  padding: 10px 8px;
  width: 96px;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 1140px) {
  .cart {
    width: 100%;
    margin: 0px auto;
  }

  .cart-table {
    width: 90%;
    margin: 32px auto 0px;
    border-collapse: collapse;
  }

  .row-title {
    padding-left: 0px !important;
  }

  .footer-contents {
    margin-right: 42px;
  }

}