nav {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  z-index: 99999 !important;
  overflow: hidden;
}

nav a:hover,
.nav-title:hover {
  color: #0d8ae1;
  cursor: pointer;
}

.logo {
  margin-top: 6px;
}

.logo svg {
  height: 28px !important;
}

.logo:hover {
  fill: #0d8ae1;
}

.cart-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #0d8ae1;
  position: absolute;
  top: 35px;
  right: 26px;
}

.nav-title {
  padding: 4px;
  margin-left: 24px;
  text-align: center;
  font-size: 22px;
  color: #222;
  letter-spacing: 2px;
}

.nav-title img {
  width: 128px;
}

.nav-links {
  margin-right: 8px;
  display: flex;
  list-style: none;
  align-items: center;

}

.nav-links a {
  color: rgb(36, 41, 46);
  text-decoration: none;
  font-size: 14px;
  padding: 0 20px;
  vertical-align: middle;
}

.nav-burger {
  display: none;
  cursor: pointer;
}

.nav-burger div {
  width:28px;
  height: 2px;
  background-color: black;
  margin: 6px 0;
}

@media (max-width: 1140px) {
  .logo {
    margin: 8px 0;
  }

  .cart-dot {
    top: 32px;
    right: 21.5px;
  }

  .nav-burger {
    display: block;
    margin-left: 24px;
  }

  ul {
    display: none;
  }
  
  .links-burger {
    left: 0;
    position: fixed;
    top: 96px;
    width: 100%;
    background-color: #fff;
    z-index: 9999;
  }

  .nav-expanded {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }

  .nav-expanded a {
    padding: 12px 24px;
    color: #000;
    text-decoration: none;
    background-color: #fff;
    margin: 0px auto;
  }

  .nav-expanded a:hover {
    color: #0d8ae1;
  }

  .nav-title {
    margin: 0px;
  }

}