@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url(./fonts/Inter-Regular.woff) format('woff');
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #f7f7f7;
  font-family: 'Roboto Mono', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .slide {
  background: none !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.carousel .control-prev.control-arrow::before {
  border-right: 10px solid #0d8ae1 !important;
}

.carousel .control-next.control-arrow::before {
  border-left: 10px solid #0d8ae1 !important;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
} */

:focus {outline:none;}
::-moz-focus-inner {border:0;}