.item-container {
  text-align: center;
  width: 340px;
  margin-top: 32px;
}

.image-container {
  margin: 0px auto;
  background-size: cover;
  background-position: center;
  height: 340px;
  width: 340px;
}

.item-image-container img {
  width: 340px;
}

.item-title {
  margin-top: 12px;
  font-size: 18px;
  color: #000;
}

.item-link-container {
  text-decoration: none;
}

.item-link-container:hover div {
  color: #0d8ae1;
}

.item-price {
  margin-top: 4px;
  font-size: 14px;
}

.item-images {
  position: relative;
  top: 0;
  left: 0;
}

.item-image-1 {
  position: relative;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity .3s cubic-bezier(.25,.46,.45,.94);
}

.item-image-1:hover {
  position: relative;
  top: 0;
  left: 0;
  opacity: 0;
}

.item-image-2 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity .3s cubic-bezier(.25,.46,.45,.94);
}

.item-image-2:hover {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
}

@media (max-width: 1140px) {
  .item-container {
    text-align: center;
    width: 100%;
    margin: 32px auto;
  }

  .item-image-container img {
    width: 80%;
  }

  .item-image-1:hover {
    opacity: 1;
  }

  .item-image-2:hover {
    opacity: 0;
  }

  .item-image-container .item-title {
    margin: 4px auto;
    width: 80%;
  }
}