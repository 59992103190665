.home-container {
  height: 100svh;
}

.home-video {
  height: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 1140px) {
  .home-container {
    margin-top: 0px;
  }
}