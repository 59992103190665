.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 72px;
  padding: 24px;
  margin-top: -16px;
}

.footer-logo {
  height: 64px;
  margin-left: 24px;
}

.footer-right {
  margin: auto 0;
}

.footer-right input {
  border: none;
  width: 164px;
  padding: 11px 12px;
  font-size: 14px;
}

.footer-right input::placeholder {
  color: #000;
  opacity: 1;
}

.footer-right button {
  border: none;
  padding: 10px 12px;
  background-color: #0d8ae1;
  color: white;
  font-size: 14px;
  font-family: 'Roboto Mono', sans-serif;
  cursor: pointer;
}

@media (max-width: 1140px) {
  .footer-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    height: auto;
    padding: 0px;
    margin-top: 16px;
  }

  .footer-logo {
    margin: 16px 0px;
  }

  .footer-right input {
    border: none;
    width: 124px;
    padding: 8px;
    font-size: 12px;
  }

  .footer-right button {
    border: none;
    padding: 6px 8px;
    background-color: #0d8ae1;
    color: white;
    font-size: 12px;
    font-family: 'Roboto Mono', sans-serif;
    cursor: pointer;
  }
}